<template>
  <v-app>
    <div :class="{ greenBg: auth.status }"></div>
    <div :class="{ greyBg: auth.status }"></div>
    <appbar />
    <drawer />
    <v-main style="z-index: 2; position: relative">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <snackbar />
  </v-app>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import appbar from '@/components/navbars/appbar'
import drawer from '@/components/navbars/drawer'
import snackbar from '@/components/widgets/snackbar'

export default {
  name: 'App',
  components: { appbar, drawer, snackbar },
  computed: { ...mapState(['auth']) },
  methods: {
    ...mapMutations(['RESET_AUTH_STATE']),
    ...mapActions(['initTicketingSystem', 'verifyToken'])
  },
  async mounted() {
    // If login, check wether the token is still valid
    if (this.auth.status) {
      const response = await this.$api.verifyToken({
        params: { token: this.auth.token }
      })

      // Force Login if the token expired
      if (!response.data.success) {
        this.RESET_AUTH_STATE()
        this.$router.push({ path: '/' })
      } else this.initTicketingSystem()
    }

    setInterval(() => {
      if (this.auth.status) this.verifyToken()
    }, 10000)
  }
}
</script>

<style scoped>
.greenBg {
  position: fixed !important;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 50vh !important;
  z-index: 1 !important;
  background: rgb(206, 45, 45) !important;
  background: linear-gradient(126deg, #2ccf8c, #2fcfc7 96%) !important;
}

.greyBg {
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 0 !important;
  background: #f5f5f5;
}
</style>

<style>
* {
  font-family: PingFangHK-Semibold, sans-serif;
}

/* width */
/* ::-webkit-scrollbar {
  width: 5px;
} */

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(187, 187, 187);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
