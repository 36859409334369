<template>
  <v-snackbar bottom right v-model="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout">
    <v-progress-circular v-if="snackbar.loading" size="24" indeterminate color="grey lighten-2" class="mr-1"></v-progress-circular>
    <v-icon v-else left color="white">mdi-{{ snackbar.icon }}</v-icon>
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }" v-if="snackbar.closable">
      <v-btn :color="snackbar.close_button_color" text v-bind="attrs" @click="setSnackbar({ status: false, text: null })">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'snackbar',
  methods: { ...mapMutations(['SET_SNACKBAR']) },
  computed: { ...mapState(['snackbar']) }
}
</script>
