import api from '@/apis'

export default {
  state: {
    selected_date_range: [],
    headers: [
      { text: '#', value: 'index', sortable: true, width: 80 },
      { text: 'Import Date', value: 'import_datetime', sortable: true },
      { text: 'Last Name', value: 'lastname', sortable: true, width: 120 },
      { text: 'Booking Date Time', value: 'booking_datetime', sortable: true },
      { text: 'Last 4 digits of phone number', value: 'tel', sortable: true },
      { text: 'Userform Submit ID', value: 'booking_id', sortable: true },
      { text: 'Action', value: 'action', sortable: false }
    ],
    items: []
  },
  mutations: {
    SET_BOOKING_ITEMS(state, payload) {
      state.items = payload
    },
    SET_BOOKING_SELECTED_DATE_RANGE(state, payload) {
      state.selected_date_range = payload
    }
  },
  actions: {
    async getBookingRecordsByDateRange({ commit }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Loading reports...', loading: true })
        const response = await api.getBookingByDate(payload)
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
          commit('SET_BOOKING_ITEMS', response.data.data)
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Occur Error', icon: 'close', timeout: '2000' })
      }
    },
    async deleteBookingRecordByID({ commit, rootState }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Deleting...', loading: true })
        const response = await api.deleteBooking(payload)
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })

          // Remove the deleted booking record from the current booking list
          let booking_list = [...rootState.bookings.items]
          const index = booking_list.findIndex(booking => booking.booking_id === payload.params.booking_id)
          booking_list.splice(index, 1)
          commit('SET_BOOKING_ITEMS', booking_list)
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Occur Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
