import api from '@/apis'

export default {
  state: {
    teams: [],
    windows: [],
    users: [],
    queues: []
  },
  mutations: {
    SET_GENERAL_TEAMS(state, payload) {
      state.teams = payload
    },
    SET_GENERAL_WINDOWS(state, payload) {
      payload.unshift({ window_id: null, name: 'All Counter' })
      state.windows = payload
    },
    SET_GENERAL_USERS(state, payload) {
      payload.unshift({ user_id: null, firstname: 'All', lastname: 'Staffs' })
      state.users = payload
    },
    SET_GENERAL_QUEUES(state, payload) {
      state.queues = payload
    }
  },
  actions: {
    initTicketingSystem({ dispatch }) {
      dispatch('fetchTeamList')
      dispatch('fetchWindowList')
      dispatch('fetchUserList')
      dispatch('fetchServiceTypeList')
      console.log('[System] Initializing...')
    },
    async fetchTeamList({ commit }) {
      try {
        const response = await api.getTeams()
        if (response.data.success) {
          commit('SET_GENERAL_TEAMS', response.data.data)
        } else {
          throw Error(response.data.message)
        }
      } catch (error) {
        console.log('Init Team List Failed - ', error.message)
      }
    },
    async fetchWindowList({ commit }) {
      try {
        const response = await api.getWindows()
        if (response.data.success) {
          commit('SET_GENERAL_WINDOWS', response.data.data)
        } else {
          throw Error(response.data.message)
        }
      } catch (error) {
        console.log('Init Window List Failed - ', error.message)
      }
    },
    async fetchUserList({ commit }) {
      try {
        const response = await api.getUsers()
        if (response.data.success) {
          commit('SET_GENERAL_USERS', response.data.data)
        } else {
          throw Error(response.data.message)
        }
      } catch (error) {
        console.log('Init User List Failed - ', error.message)
      }
    }
  }
}
