import api from '@/apis'

export default {
  state: {
    visibility: false,
    from_level: null,
    from_level_data: null
  },
  mutations: {
    INIT_NEW_ROUTE_DIALOG(state, payload) {
      state.visibility = true
      state.from_level = payload.from_level
      state.from_level_data = payload.data
    },
    SET_NEW_ROUTE_DIALOG_VISIBILITY(state, payload) {
      state.visibility = payload
    }
  },
  actions: {
    async createNewRoute({ commit, rootState, dispatch }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Creating route...', loading: true })
        const response = await api.createRoute(payload)
        if (response.data.success) {
          commit('SET_NEW_ROUTE_DIALOG_VISIBILITY', false)
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })

          // Update the route list
          if (rootState.newRouteDialog.from_level === 2) {
            dispatch('fetchLevel2Route', payload.params.parent_id)
          } else {
            dispatch('fetchLevel3Route', payload.params.parent_id)
          }
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
