import api from '@/apis'

export default {
  state: {
    loading: true,
    headers: [
      { text: 'Av waiting (Min)', value: 'average_waiting_time', width: '100', sortable: false },
      { text: 'Queue', value: 'prefix', width: '80', sortable: false },
      { text: 'Service Type', value: 'service_type', width: '180', sortable: false },
      { text: 'No. of ticket before Keypad trigger', value: 'numpad_threshold', width: '120', sortable: false },
      { text: 'No. of ticket before SMS trigger', value: 'sms_threshold', width: '115', sortable: false },
      { text: 'Threshold(Mins)', value: 'threshold', sortable: false }
    ],
    items: []
  },
  mutations: {
    RESET_QUEUE_SETTING_VIEW(state) {
      state.loading = true
      state.items = []
    },
    SET_QUEUE_SETTING_LOADING(state, payload) {
      state.loading = payload
    },
    SET_QUEUE_SETTING_ITEMS(state, payload) {
      state.items = payload
    },
    UPDATE_QUEUE_ITEM(state, { service_type_id, type, value }) {
      const index = state.items.findIndex(item => item.service_type_id === service_type_id)
      state.items[index][type] = value
    }
  },
  actions: {
    async resetQueue({ commit }) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Resetting...', loading: true })
        const response = await api.resetQueue()
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    },
    async fetchServiceTypeList({ commit }) {
      try {
        commit('RESET_QUEUE_SETTING_VIEW')
        const response = await api.getServiceTypes()
        if (response.data.success) {
          commit('SET_GENERAL_QUEUES', response.data.data)
          commit('SET_QUEUE_SETTING_ITEMS', response.data.data)
          commit('SET_MENU_SETTING_SERVICE_TYPE_LIST', response.data.data)
          commit('SET_QUEUE_SETTING_LOADING', false)
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    },
    async updateServiceTypeThreshold({ commit }, payload) {
      try {
        const response = await api.updateServiceType(payload)
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Updating...', loading: true })
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
