<template>
  <v-app-bar hide-on-scroll app elevation="0" color="transparent" v-if="auth.status">
    <v-app-bar-nav-icon style="color: white" @click.stop="SET_NAVBAR_VISIBILITY(!navbar.visibility)"></v-app-bar-nav-icon>
    <v-toolbar-title style="color: white; font-weight: 500" class="d-none d-sm-none d-md-flex">{{ $route.name }}</v-toolbar-title>
    <v-spacer></v-spacer>

    <!-- User info -->
    <v-menu open-on-hover bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="transparent" elevation="0" dark v-bind="attrs" v-on="on" class="text-capitalize">
          {{ auth.user_info.firstname }} {{ auth.user_info.lastname }}
          <v-icon right dark> mdi-menu-down </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-card class="mx-auto" max-width="344" flat>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-2">Information</div>
                <v-list-item-title class="headline text-capitalize">{{ auth.user_info.firstname }} {{ auth.user_info.lastname }}</v-list-item-title>
                <v-list-item-subtitle v-if="auth.user_info.type === 'super_admin'"> Super Admin </v-list-item-subtitle>
                <v-list-item-subtitle v-else>Service Account</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar tile size="100" color="grey">
                <v-img contain :src="getUserAvatar"></v-img>
              </v-list-item-avatar>
            </v-list-item>

            <v-card-actions>
              <v-btn outlined rounded small block @click="logoutUser({ token: auth.token, sso: $auth })"> Logout </v-btn>
            </v-card-actions>
          </v-card>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'appbar',
  computed: {
    ...mapState(['auth', 'navbar']),
    getUserAvatar() {
      return this.auth.user_info.avatar_url ? this.auth.user_info.avatar_url : null
    }
  },
  methods: {
    ...mapMutations(['SET_NAVBAR_VISIBILITY']),
    ...mapActions(['logoutUser'])
  }
}
</script>

<style scoped></style>
