import api from '@/apis'

export default {
  state: {
    loading: true,
    headers: [
      { text: 'ID', value: 'user_id', sortable: false },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'General Question', value: 'skill_1', sortable: false },
      { text: 'Illness Claims', value: 'skill_2', sortable: false },
      { text: 'Express Query', value: 'skill_3', sortable: false },
      { text: 'Others Claims', value: 'skill_4', sortable: false },
      { text: 'MPF', value: 'skill_5', sortable: false },
      { text: 'Payment', value: 'skill_6', sortable: false },
      { text: 'Doc Collection', value: 'skill_7', sortable: false },
      { text: 'Reserved', value: 'skill_8', sortable: false }
    ],
    items: []
  },
  mutations: {
    RESET_SKILLSET_CARD(state) {
      state.loading = true
      state.items = []
    },
    SET_SKILLSET_CARD_LOADING(state, payload) {
      state.loading = payload
    },
    SET_SKILLSET_CARD_ITEMS(state, payload) {
      state.items = payload
    }
  },
  actions: {
    async getSkillsetByTeamID({ commit }, payload) {
      try {
        const response = await api.getSkillsetByTeamID(payload)
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: `Loading User's Skillset...`, loading: true })
        commit('RESET_SKILLSET_CARD')
        if (response.data.success) {
          const users_skill_set = response.data.data.map(user => {
            user.skillset = user.skillset === null ? [] : user.skillset
            return user
          })
          commit('SET_SKILLSET_CARD_ITEMS', users_skill_set)
          commit('SET_SKILLSET_CARD_LOADING', false)
          commit('SET_SNACKBAR', { state: false })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    },
    async updateUserSkillSet({ commit }, payload) {
      try {
        const response = await api.updateSkillset(payload)
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Updating...', loading: true })
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
