import api from '@/apis'

export default {
  state: {
    disable_export_btn: true,
    report_type_list: [
      {
        title: 'Detail Report',
        value: 'detail_report'
      },
      {
        title: 'Report Summary By Queue',
        value: 'queue_summary_report'
      },
      {
        title: 'Report Summary By Staff',
        value: 'staff_summary_report'
      }
    ],
    datatable: {
      headers: [
        { text: '#', value: 'index', sortable: false, width: 80 },
        { text: 'Report', value: 'filename', sortable: false },
        { text: 'Status', value: 'status', sortable: false, width: 120 },
        { text: 'Create Date', value: 'create_datetime', sortable: false, width: 200 },
        { text: '', value: 'action', sortable: false, width: 80 }
      ],
      items: []
    }
  },
  mutations: {
    SET_REPORTS_LIST(state, payload) {
      state.datatable.items = payload
    },
    SET_REPORTS_DISABLE_EXPORT_BTN(state, payload) {
      state.disable_export_btn = payload
    }
  },
  actions: {
    async searchReport({ commit }, payload) {
      try {
        const params = { from_date: payload.from_date, to_date: payload.to_date, report_type: payload.report_type }
        const response = await api.getReports(params)
        if (response.data.success) {
          const result = response.data.data
          commit('SET_REPORTS_LIST', result)
          if (result.length === 1 && result[0].status === 'Done') {
            commit('SET_REPORTS_DISABLE_EXPORT_BTN', false)
            commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Generate report success', icon: 'check', timeout: '2000' })
          }
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    },
    async exportReport({ commit }, payload) {
      try {
        commit('SET_REPORTS_LIST', [])
        const response = await api.exportReport(payload)
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Exporting...', loading: true })
        if (response.data.success) {
          commit('SET_REPORTS_DISABLE_EXPORT_BTN', true)
          commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Generating report...', loading: true })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
