import api from '@/apis'

export default {
  state: {
    service_type_list: [],
    level_1_menu_data: [],
    level_2_menu_data: {
      visibility: false,
      breadcrumbs: [
        {
          text: 'Menu',
          disabled: true
        },
        {
          text: 'General Enquiry',
          disabled: false
        }
      ],
      headers: [
        { text: '#', value: 'index', sortable: false },
        { text: 'Chinese', value: 'name', sortable: false },
        { text: 'CN', value: 'name_cn', sortable: false },
        { text: 'English', value: 'name_en', sortable: false },
        { text: 'Queue', value: 'queue', width: 100, sortable: false },
        { text: 'Edit', value: 'edit', sortable: false }
      ],
      items: []
    },
    level_3_menu_data: {
      visibility: false,
      title: null,
      headers: [
        { text: 'Chinese', value: 'name', sortable: false },
        { text: 'CN', value: 'name_cn', sortable: false },
        { text: 'English', value: 'name_en', sortable: false },
        { text: 'Queue', value: 'queue', sortable: false },
        { text: 'Edit', value: 'edit', sortable: false }
      ],
      items: []
    }
  },
  mutations: {
    SET_MENU_SETTING_LEVEL_1_MENU(state, payload) {
      state.level_1_menu_data = payload
    },
    SET_MENU_SETTING_LEVEL_2_MENU(state, payload) {
      state.level_2_menu_data.visibility = payload.visibility
      state.level_2_menu_data.breadcrumbs[1].text = payload.title
      state.level_2_menu_data.items = payload.items
    },
    SET_MENU_SETTING_LEVEL_2_MENU_VISIBILITY(state, payload) {
      state.level_2_menu_data.visibility = payload
    },
    SET_MENU_SETTING_LEVEL_3_MENU(state, payload) {
      state.level_3_menu_data.visibility = payload.visibility
      state.level_3_menu_data.title = payload.title
      state.level_3_menu_data.items = payload.items
    },
    SET_MENU_SETTING_LEVEL_3_MENU_VISIBILITY(state, payload) {
      state.level_3_menu_data.visibility = payload
    },
    SET_MENU_SETTING_SERVICE_TYPE_LIST(state, payload) {
      let service_type_list = JSON.parse(JSON.stringify(payload))
      service_type_list.unshift({ service_type_id: null, service_type: 'None', prefix: 'None' })
      state.service_type_list = service_type_list
    }
  },
  actions: {
    async fetchRootLevelRoute({ commit }) {
      try {
        const response = await api.getRootRoutes()
        if (response.data.success) {
          commit('SET_MENU_SETTING_LEVEL_1_MENU', response.data.data.child)
        }
      } catch (error) {
        console.log('Init Root Level Route Error')
      }
    },
    async fetchLevel2Route({ commit }, payload) {
      try {
        const response = await api.getChildRoutes(payload)
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Loading route...', loading: true })
        if (response.data.success) {
          const route = response.data.data
          commit('SET_MENU_SETTING_LEVEL_2_MENU', {
            visibility: true,
            title: route.parent.name,
            items: route.child
          })
          commit('SET_SNACKBAR', { status: false })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    },
    async fetchLevel3Route({ commit }, payload) {
      try {
        const response = await api.getChildRoutes(payload)
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Loading route...', loading: true })
        if (response.data.success) {
          const route = response.data.data
          commit('SET_MENU_SETTING_LEVEL_3_MENU', {
            visibility: true,
            title: route.parent.name,
            items: route.child
          })
          commit('SET_SNACKBAR', { status: false })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
