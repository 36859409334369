export default {
  // Login & Logout
  login: `/auth/login`,
  login_by_sso: `auth/login/sso`,
  logout: `/auth/logout`,
  verify_token: `/auth/verify_token`,

  // Teams
  teams: `/teams`,

  // Windows
  window: `/windows`,

  // Users
  users: `/users`,

  // Skillsets
  skillsets: `/skillsets`,

  // Service Types
  service_types: `/service_types`,

  // Routes
  routes: `/routes`,

  // Reports
  reports: '/reports',

  // Queues
  queues: '/queues',

  // Booking
  bookings: '/bookings',

  // Exchange Rate
  exchange_rate: '/exchange_rate'
}
