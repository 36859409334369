import axios from 'axios'
import store from '@/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_SERVER_ENDPOINT
})

// Request interceptor
instance.interceptors.request.use(
  config => {
    // Embed the auth token to each API request
    if (!config.url.includes('/login') && !config.url.includes('/logout')) {
      config.headers.token = store.state.auth.token
    }
    return config
  },
  error => {
    console.error('error:', error)
    Promise.reject(error)
  }
)

// Response interceptor
instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const err_msg = error.message
    if (err_msg === 'Network Error' || err_msg.includes(401)) store.dispatch('logoutUser')
    return Promise.reject(error)
  }
)

export default instance
