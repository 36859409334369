import api from '@/apis'

export default {
  state: {
    visibility: false,
    from_level: null,
    selected_route_data: null
  },
  mutations: {
    INIT_EDIT_ROUTE_DIALOG(state, payload) {
      state.visibility = true
      state.from_level = payload.from_level
      state.selected_route_data = payload.data
    },
    SET_EDIT_ROUTE_DIALOG_VISIBILITY(state, payload) {
      state.visibility = payload
    }
  },
  actions: {
    async updateRoute({ commit, rootState, dispatch }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Updating route...', loading: true })
        const response = await api.updateRoute(payload)
        if (response.data.success) {
          commit('SET_EDIT_ROUTE_DIALOG_VISIBILITY', false)
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })

          // Update the route list
          if (rootState.editRouteDialog.from_level === 1) {
            dispatch('fetchRootLevelRoute')
          } else if (rootState.editRouteDialog.from_level === 2) {
            dispatch('fetchLevel2Route', rootState.editRouteDialog.selected_route_data.parent_id)
          } else {
            dispatch('fetchLevel3Route', rootState.editRouteDialog.selected_route_data.parent_id)
          }
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    },
    async deleteRoute({ commit, rootState, dispatch }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Deleting route...', loading: true })
        const response = await api.deleteRoute(payload)
        if (response.data.success) {
          commit('SET_EDIT_ROUTE_DIALOG_VISIBILITY', false)
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })

          // Update the route list
          if (rootState.editRouteDialog.from_level === 2) {
            dispatch('fetchLevel2Route', rootState.editRouteDialog.selected_route_data.parent_id)
            commit('SET_MENU_SETTING_LEVEL_3_MENU_VISIBILITY', false)
          } else {
            dispatch('fetchLevel3Route', rootState.editRouteDialog.selected_route_data.parent_id)
          }
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
