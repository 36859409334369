import api from '@/apis'

export default {
  state: {
    visibility: false
  },
  mutations: {
    SET_IMPORT_BOOKING_DIALOG_VISIBILITY(state, payload) {
      state.visibility = payload
    }
  },
  actions: {
    async importBookingRecords({ commit, dispatch, rootState }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Importing...', loading: true })
        const response = await api.insertBooking(payload)
        if (response.data.success) {
          commit('SET_IMPORT_BOOKING_DIALOG_VISIBILITY', false)
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
          // Update the list by re-fetching the data
          dispatch('getBookingRecordsByDateRange', {
            from_date: rootState.bookings.selected_date_range[0],
            to_date: rootState.bookings.selected_date_range[1]
          })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
      }
    }
  }
}
