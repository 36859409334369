<template>
  <v-navigation-drawer v-model="navbar.visibility" app v-if="auth.status">
    <v-list dense>
      <v-list-item>
        <v-list-item-avatar height="86" width="86" class="ma-auto">
          <v-avatar size="86">
            <v-img :src="require('@/assets/images/logo.png')"></v-img>
          </v-avatar>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item class="mb-5">
        <span class="ma-auto text-body-2">Ticketing System</span>
      </v-list-item>

      <v-list-item v-for="route in routes" :key="route.id" link class="lighten-3" :class="{ grey: checkCurrentRoute(route.title) }" @click="routeTo(route.route_to)">
        <v-list-item-action>
          <v-icon small :style="route.icon_style">{{ route.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ route.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="logoutUser({ token: auth.token, sso: $auth })">
        <v-list-item-action>
          <v-icon small style="margin-left: 3px">fas fa-sign-out-alt</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'drawer',
  computed: {
    ...mapState(['auth', 'navbar']),
    routes() {
      return this.navbar.routes
    }
  },
  methods: {
    ...mapActions(['logoutUser']),
    routeTo(path) {
      if (this.$route.path !== `/${path}`) {
        this.$router.push({ path: `/${path}` })
      }
    },
    checkCurrentRoute(currentRoute) {
      return this.$route.name === currentRoute
    }
  }
}
</script>

<style scoped>
.v-list-item__action:first-child {
  margin-right: 16px !important;
}
.v-list-item__title {
  color: rgba(0, 0, 0, 0.87) !important;
}

.v-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
