import api from '@/apis'
import router from '@/router'

export default {
  state: {
    status: false,
    method: null,
    user_info: {},
    token: null
  },
  mutations: {
    SET_AUTH_STATE(state, payload) {
      state.status = payload.status
      state.method = payload.method
      state.user_info = payload.user_info
      state.token = payload.token
    },
    RESET_AUTH_STATE(state) {
      state.status = false
      state.method = null
      state.user_info = {}
      state.token = null
    }
  },
  actions: {
    async verifyToken({ commit, state }) {
      try {
        if (state.status) {
          const response = await api.verifyToken({
            params: { token: state.token }
          })
          if (!response.data.success) throw Error(response.data.message)
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
        commit('RESET_AUTH_STATE')
        router.push({ path: '/login' })
      }
    },
    // SSO Login
    async authenticateUserBySSO({ commit, dispatch }, payload) {
      try {
        const response = await api.loginBySSO(payload)
        if (response.data.success) {
          commit('SET_AUTH_STATE', {
            status: true,
            method: 'sso',
            user_info: response.data.data.user_info,
            token: response.data.data.token
          })
          dispatch('initTicketingSystem')
          router.push({ path: '/dashboard' })
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Login Success', icon: 'check', timeout: '2000' })
        } else {
          throw Error(response.data.message)
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: error.message, icon: 'close', timeout: '2000' })
        router.push({ path: '/login' })
      }
    },
    // Normal Login
    async authenticateUser({ commit, dispatch }, payload) {
      const { username, password } = payload
      if (username === null || password === null || username.trim() === '' || password.trim() === '') {
        commit('SET_SNACKBAR', { status: true, color: 'error', icon: 'fas fa-times', text: 'Please fill in username and password', timeout: 2000 })
      } else {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Authenticating your identity...', loading: true })
        try {
          const response = await api.login({ params: payload })
          if (response.data.success) {
            commit('SET_AUTH_STATE', {
              status: true,
              method: 'normal',
              user_info: response.data.data.user_info,
              token: response.data.data.token
            })
            dispatch('initTicketingSystem')
            router.push({ path: '/dashboard' })
            commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Login Success', icon: 'check', timeout: '2000' })
          } else {
            commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
          }
        } catch (error) {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
        }
      }
    },
    async logoutUser({ commit, state }, { token = null, sso = null }) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Logging out...', loading: true })
        api.logout({ params: { token: token ? token : state.token } })
        if (state.method === 'sso' && sso) await sso.signOut()
      } catch (error) {
        console.log('Logout error', error.message)
      } finally {
        commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Logout Success', icon: 'check', timeout: '2000' })
        commit('RESET_AUTH_STATE')
        router.push({ path: '/login' })
      }
    }
  }
}
