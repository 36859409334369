import api from '@/apis'

export default {
  state: {
    loading: true,
    headers: [
      { text: 'Avatar', value: 'avatar', sortable: false },
      { text: 'ID', value: 'user_id' },
      { text: 'Team', value: 'team', width: 140 },
      { text: 'First Name', value: 'firstname' },
      { text: 'Last Name', value: 'lastname' },
      { text: 'Last Login', value: 'last_login_datetime' },
      { text: 'Active', value: 'active' }
    ],
    items: []
  },
  mutations: {
    SET_ACCOUNT_INFO_CARD_LOADING(state, payload) {
      state.loading = payload
    },
    SET_ACCOUNT_INFO_CARD_ITEMS(state, payload) {
      state.items = payload
    }
  },
  actions: {
    async fetchUserListByTeamID({ commit }, payload) {
      try {
        const response = await api.getUsersByTeamID(payload)
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Loading User List...', loading: true })
        if (response.data.success) {
          let users = response.data.data.map(user => {
            user.status = user.status === 1 ? true : false
            return user
          })
          commit('SET_ACCOUNT_INFO_CARD_ITEMS', users)
          commit('SET_ACCOUNT_INFO_CARD_LOADING', false)
          commit('SET_SNACKBAR', { state: false })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    },
    async updateAccountStatus({ commit }, payload) {
      try {
        const response = await api.updateUsers(payload)
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Updating...', loading: true })
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    },
    async updateUserTeam({ commit, dispatch }, payload) {
      try {
        const response = await api.updateUsers(payload)
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Updating...', loading: true })
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Success', icon: 'check', timeout: '2000' })
          dispatch('fetchUserListByTeamID', payload.current_team)
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: response.data.message, icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
