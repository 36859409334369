import api from '@/apis'
import Vue from 'vue'

export default {
  state: {
    card: {
      total_ticket_handled: null,
      tickets_over_threshold_count: null,
      avg_serving_time: null,
      avg_waiting_time: null,
      longest_waiting_time: null,
      no_show_ticket: null,
      cancelled_ticket: null,
      queuing_ticket: null
    },
    chart: {
      date_distribution: [],
      service_type_distribution: [],
      average_waiting_time_distribution: []
    }
  },
  mutations: {
    SET_DASHBOARD_SUMMARY_REPORT(state, payload) {
      state.card.total_ticket_handled = payload.completed.length
      state.card.tickets_over_threshold_count = payload.tickets_over_threshold_count
      state.card.avg_serving_time = payload.serving_time
      state.card.avg_waiting_time = payload.waiting_time
      state.card.longest_waiting_time = payload.longest_waiting_time
      state.card.no_show_ticket = payload.missed.length
      state.card.cancelled_ticket = payload.cancelled.length
      state.card.queuing_ticket = payload.queuing.length
    },
    SET_DASHBOARD_DETAIL_REPORT(state, payload) {
      // Formatting the date format
      const date_distribution = payload.date_distribution
      if (date_distribution.length) {
        date_distribution.forEach((record, index) => {
          date_distribution[index].date = Vue.moment(record.date).format('YYYY-MM-DD')
        })
      }

      state.chart.date_distribution = date_distribution
      state.chart.service_type_distribution = payload.service_type_distribution
      state.chart.average_waiting_time_distribution = payload.average_waiting_time_distribution
    }
  },
  actions: {
    async fetchSummaryReport({ commit }, payload) {
      try {
        commit('SET_SNACKBAR', { status: true, color: 'loading', text: 'Loading reports...', loading: true })
        const response = await api.getSummaryReport(payload)
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: false })
          commit('SET_DASHBOARD_SUMMARY_REPORT', response.data.data)
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Invalid information provided, please try again.', icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    },
    async fetchDetailReport({ commit }, payload) {
      try {
        const response = await api.getDetailReport(payload)
        if (response.data.success) {
          commit('SET_DASHBOARD_DETAIL_REPORT', response.data.data)
        } else {
          commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Invalid information provided, please try again.', icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
