import api from '@/apis'

export default {
  state: {
    rate: null
  },
  mutations: {
    UPDATE_EXCHANGE_RATE(state, payload) {
      state.rate = payload
    }
  },
  actions: {
    async fetchExchangeRate({ commit }) {
      try {
        const response = await api.getExchangeRate()
        if (response.data.success) {
          const { exchange_rate } = response.data.data
          commit('UPDATE_EXCHANGE_RATE', exchange_rate)
        } else throw Error()
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Occur Error', icon: 'close', timeout: '2000' })
      }
    },
    async updateExchangeRate({ state, commit }) {
      try {
        const params = { exchange_rate: state.rate }
        const response = await api.updateExchangeRate({ params })
        if (response.data.success) {
          commit('SET_SNACKBAR', { status: true, color: 'success', text: 'Update Exchange Rate Success', icon: 'check', timeout: '2000' })
        } else throw Error()
      } catch (error) {
        commit('SET_SNACKBAR', { status: true, color: 'error', text: 'Server Occur Error', icon: 'close', timeout: '2000' })
      }
    }
  }
}
