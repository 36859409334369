import axios from './axios.js'
import url from './urls.js'

export default {
  // Auth
  login: data => axios.post(url.login, data.params, data.headers),
  loginBySSO: data => axios.post(url.login_by_sso, data.params, data.headers),
  logout: data => axios.post(url.logout, data.params),
  verifyToken: data => axios.post(url.verify_token, data.params),

  // Windows
  getWindows: () => axios.get(url.window),

  // Teams
  getTeams: () => axios.get(url.teams),
  getSpecificTeam: team_id => axios.get(`${url.teams}?team_id=${team_id}`),

  // Users
  getUsers: () => axios.get(url.users),
  getUsersByTeamID: team_id => axios.get(`${url.users}?team_id=${team_id}`),
  getSpecificUser: user_id => axios.get(`${url.users}?user_id=${user_id}`),
  updateUsers: data => axios.put(url.users, data.params),

  // Skillset
  getSkillsetByTeamID: team_id => axios.get(`${url.skillsets}?team_id=${team_id}`),
  updateSkillset: data => axios.put(url.skillsets, data.params),

  // Service Types
  getServiceTypes: () => axios.get(url.service_types),
  updateServiceType: data => axios.put(url.service_types, data.params),

  // Routes
  getRootRoutes: () => axios.get(url.routes),
  getChildRoutes: route_id => axios.get(`${url.routes}?route_id=${route_id}`),
  createRoute: data => axios.post(url.routes, data.params),
  updateRoute: data => axios.put(url.routes, data.params),
  deleteRoute: data => axios.delete(url.routes, { data: data.params }),

  // Reports (Dashboard)
  getSummaryReport: data => axios.get(`${url.reports}/summary?${new URLSearchParams(data).toString()}`),
  getDetailReport: data => axios.get(`${url.reports}/details?${new URLSearchParams(data).toString()}`),

  // Reports (Performance Report)
  getReports: data => axios.get(`${url.reports}/export?${new URLSearchParams(data).toString()}`),
  exportReport: data => axios.post(`${url.reports}/export`, data.params, data.headers),
  downloadReport: data => axios.get(`${url.reports}/download?${new URLSearchParams(data).toString()}`),

  // Queues
  resetQueue: () => axios.put(`${url.queues}/reset`),

  // Bookings
  getBookingByDate: data => axios.get(`${url.bookings}?${new URLSearchParams(data).toString()}`),
  insertBooking: data => axios.post(url.bookings, data.params, data.headers),
  deleteBooking: data => axios.delete(url.bookings, { data: data.params }),

  // Exchange Rate
  getExchangeRate: () => axios.get(`${url.exchange_rate}`),
  updateExchangeRate: data => axios.put(`${url.exchange_rate}`, data.params)
}
