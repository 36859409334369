export default {
  state: {
    visibility: true,
    routes: [
      {
        id: 1,
        route_to: 'dashboard',
        title: 'Dashboard',
        icon: 'fas fa-home',
        icon_style: ''
      },
      {
        id: 2,
        route_to: 'bookings',
        title: 'Bookings',
        icon: 'fas fa-bookmark',
        icon_style: 'margin-left: 3px'
      },
      {
        id: 3,
        route_to: 'accounts',
        title: 'Accounts',
        icon: 'fas fa-user',
        icon_style: 'margin-left: 2px'
      },
      {
        id: 4,
        route_to: 'reports',
        title: 'Reports',
        icon: 'fas fa-book',
        icon_style: 'margin-left: 2px'
      },
      {
        id: 5,
        route_to: 'menus',
        title: 'Menu Setting',
        icon: 'fas fa-bezier-curve',
        icon_style: ''
      },
      {
        id: 6,
        route_to: 'queues',
        title: 'Queue Setting',
        icon: 'fas fa-clock',
        icon_style: 'margin-left: 2px'
      },
      {
        id: 7,
        route_to: 'exchange_rate',
        title: 'Exchange Rate',
        icon: 'fas fa-dollar-sign',
        icon_style: 'margin-left: 6px'
      }
    ]
  },
  mutations: {
    SET_NAVBAR_VISIBILITY(state, payload) {
      state.visibility = payload
    }
  }
}
