export default {
  state: {
    status: false,
    text: null,
    color: 'success',
    timeout: -1,
    close_button_color: null,
    closable: false,
    loading: false,
    icon: null
  },
  mutations: {
    SET_SNACKBAR(state, payload) {
      // Snackbar visibility
      state.status = typeof payload.status !== 'undefined' ? payload.status : false

      // Text
      state.text = typeof payload.text !== 'undefined' ? payload.text : null

      // display time
      state.timeout = typeof payload.timeout !== 'undefined' ? payload.timeout : -1

      // Close button color
      state.close_button_color = typeof payload.close_button_color !== 'undefined' ? payload.close_button_color : null

      // Close button visibility
      state.closable = typeof payload.closable !== 'undefined' ? payload.closable : false

      // Loading Icon
      state.loading = typeof payload.loading !== 'undefined' ? payload.loading : false

      // Icon
      state.icon = typeof payload.icon !== 'undefined' ? payload.icon : null

      // Snackbar color
      if (typeof payload.color !== 'undefined') {
        switch (payload.color) {
          case 'success':
            state.color = 'success'
            break
          case 'loading':
            state.color = 'grey darken-1'
            break
          case 'error':
            state.color = 'error'
            break
        }
      }
    }
  }
}
